import ApiService from '@/core/services/api.service'

export const LIST_RIGHTS = 'listRights'
export const SET_RIGHTS = 'setRights'

export const SET_ERROR = 'setError'
export const RESET_ERROR = 'resetError'

const state = {
    error: null,
    invoices: [],
    invoice: {}
}

const getters = {
    listRights(state) {
        return state.rights
    }
}

const actions = {
    // LIST
    [LIST_RIGHTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('formation/rights/user', params)
                .then(({ data }) => {
                    context.commit(SET_RIGHTS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_RIGHTS](state, data) {
        state.rights = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
