// intern types
import axios from 'axios'
import authHeader from '@/core/services/store/api-orion/auth-header'

// action types
export const LIST_CLIENTS_PRODUITS = 'listClientsProduits'

// mutation types
export const SET_ERROR = 'setError'
export const SET_CLIENTS_PRODUITS = 'setClientsProduits'

const getDefaultState = () => {
    return {
        error: null,
        clientsProduits: []
    }
}

const API_URL = process.env.VUE_APP_API_ORION_URL

const state = getDefaultState()

const getters = {
    listCodesProduits(state) {
        return state.clientsProduits
    }
}

const actions = {
    [LIST_CLIENTS_PRODUITS](context) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'clients/produits', {headers: authHeader() })
                .then(({ data }) => {
                    context.commit(SET_CLIENTS_PRODUITS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_CLIENTS_PRODUITS](state, data) {
        state.clientsProduits = data
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
