import ApiService from '@/core/services/api.service'

// date types
export const LIST_ACTION_DATES = 'listActionsDates'

// mutation types
export const SET_ERROR = 'setError'
export const SET_DATES = 'setDates'
export const RESET_ERROR = 'resetError'

const state = {
    error: null,
    dates: []
}

const getters = {
    listDates(state) {
        return state.dates
    }
}

const actions = {
    [LIST_ACTION_DATES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query(`formation/actions/${params.id}/dates`, params)
                .then(({ data }) => {
                    context.commit(SET_DATES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_DATES](state, data) {
        state.dates = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
