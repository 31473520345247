// intern types
import axios from 'axios'
import authHeader from '@/core/services/store/api-orion/auth-header'

// action types
export const LIST_AVIS_STAGIAIRES = 'listavisStagiaires'
export const AVIS_STAGIAIRES = 'listAvisStagiaires'

// mutation types
export const SET_ERROR = 'setError'
export const SET_AVIS_STAGIAIRES = 'setavisStagiaires'

const getDefaultState = () => {
    return {
        error: null,
        avisStagiaires: []
    }
}

const API_URL = process.env.VUE_APP_API_ORION_URL

const state = getDefaultState()

const getters = {
    listAvisStagiaires(state) {
        return state.avisStagiaires
    }
}

const actions = {
    [LIST_AVIS_STAGIAIRES](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'avisstagiaires/' + params.data.customerId + '/' + params.data.codeProduitId, { headers: authHeader() })
                .then(({ data }) => {
                    context.commit(SET_AVIS_STAGIAIRES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [AVIS_STAGIAIRES](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'avisstagiaires/details/' + params.acl_soc + '/' + params.acl_id, { headers: authHeader() })
                .then(({ data }) => {
                    context.commit(SET_AVIS_STAGIAIRES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_AVIS_STAGIAIRES](state, data) {
        state.avisStagiaires = data
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
