import Vue from 'vue'
import Router from 'vue-router'

// middlewares
import manager from '@/core/middlewares/manager'
import intern from '@/core/middlewares/intern'
import right from '@/core/middlewares/right'
import settings from '@/core/middlewares/settings'

Vue.use(Router)

export default new Router({
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
            }
        } else {
            return { x: 0, y: 0 }
        }
    },
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('@/view/layout/Layout'),
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('@/view/pages/Home.vue')
                },
                {
                    path: 'actions/:type',
                    name: 'action.list',
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/action/List.vue')
                },
                {
                    path: 'actions-closed/:type',
                    name: 'action.closed',
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/action/List.vue')
                },
                {
                    path: 'action/:societe/:id',
                    name: 'action.view',
                    props: true,
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/action/View.vue')
                },
                {
                    path: 'stagiaires',
                    name: 'intern.list',
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/intern/List.vue')
                },
                {
                    path: 'stagiaire/:extid?/:plateformeid?',
                    name: 'intern.view',
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/intern/View.vue')
                },
                {
                    path: 'factures',
                    name: 'invoice.list',
                    meta: {
                        middleware: [manager, right]
                    },
                    component: () => import('@/view/pages/invoice/List.vue')
                },
                {
                    path: 'evacuations',
                    name: 'evacuation.list',
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/evacuation/List.vue')
                },
                {
                    path: 'calendrier',
                    name: 'calendar',
                    props: true,
                    meta: {
                        middleware: [manager]
                    },
                    component: () => import('@/view/pages/action/widget/Calendar.vue')
                },
                {
                    path: 'statistiques',
                    name: 'statistiques',
                    props: true,
                    meta: {
                        middleware: [manager, settings]
                    },
                    component: () => import('@/view/pages/statistiques/Statistiques.vue')
                },
                {
                    path: 'effectif-forme',
                    name: 'statistiques.trained',
                    props: true,
                    meta: {
                        middleware: [manager, settings]
                    },
                    component: () => import('@/view/pages/statistiques/StatistiquesDetails.vue')
                },
                {
                    path: '/avis-de-stage',
                    name: 'internship-notice',
                    props: true,
                    meta: {
                        middleware: [manager, settings]
                    },
                    component: () => import('@/view/pages/internship-notice/List.vue')
                },
                {
                    path: '/avis-de-stage/liste',
                    name: 'internship-notice.list',
                    props: true,
                    meta: {
                        middleware: [manager, settings]
                    },
                    component: () => import('@/view/pages/internship-notice/ActionList.vue')
                },
                {
                    path: '/avis-de-stage/details',
                    name: 'internship-notice.details',
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if (to.params.acl_id && to.params.acl_soc) {
                            next()
                        } else {
                            next({ name: 'internship-notice' })
                        }
                    },
                    meta: {
                        middleware: [manager, settings]
                    },
                    component: () => import('@/view/pages/internship-notice/Details.vue')
                }
            ]
        },
        {
            path: '/espace-stagiaire',
            component: () => import('@/view/layout/Layout'),
            children: [
                {
                    path: 'actions',
                    name: '_intern.action.list',
                    meta: {
                        middleware: [intern]
                    },
                    component: () => import('@/view/pages/_intern/action/List.vue')
                },
                {
                    path: 'actions/:societe/:id',
                    name: '_intern.action.view',
                    meta: {
                        middleware: [intern]
                    },
                    component: () => import('@/view/pages/_intern/action/View.vue')
                }
            ]
        },
        {
            path: '*',
            redirect: '/404'
        },
        {
            // the 404 route, when none of the above matches
            path: '/404',
            name: '404',
            component: () => import('@/view/pages/Error.vue')
        }
    ]
})