import Vue from 'vue'

Vue.filter('toCurrency', function(value) {
    if (typeof value !== 'number') {
        return value
    }
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
    })
    return formatter.format(value)
})
