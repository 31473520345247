import ApiService from '@/core/services/api.service'

// action types
export const GET_SOURCE_EXT = 'getSourceExt'
export const GET_SOURCE = 'getSource'

// mutation types
export const SET_ERROR = 'setError'
export const SET_SOURCE_EXT = 'setSourceExt'
export const SET_SOURCE = 'setSource'
export const RESET_ERROR = 'resetError'
export const RESET_SOURCE_STATE = 'resetSourceState'

const state = {
    error: null,
    source: {}
}

const getters = {
    getSourceExt(state) {
        return state.source
    },
    getSource(state) {
        return state.source
    }
}

const actions = {
    [GET_SOURCE_EXT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('table-sources/sourceExt/' + params.params.tablename + '/' + params.params.tableid)
                .then(({ data }) => {
                    context.commit(SET_SOURCE_EXT, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [GET_SOURCE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('table-sources/source/' + params.params.extname + '/' + params.params.extid)
                .then(({ data }) => {
                    context.commit(SET_SOURCE, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_SOURCE_EXT](state, data) {
        state.source = data
        state.error = null
    },
    [SET_SOURCE](state, data) {
        state.source = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
