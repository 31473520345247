import store from '@/core/services/store'

export default function authHeader() {
    let token = store.getters.token
    if (token) {
        return { Authorization: 'Bearer ' + token }
    } else {
        return {}
    }
}
