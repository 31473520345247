import ApiService from '@/core/services/api.service'

// evacuation types
export const LIST_STATISTIQUES = 'listStatistiques'
export const GET_STATISTIQUE = 'getStatistique'
export const GET_USERS_THEMES = 'getUsersThemes'
// mutation types
export const SET_ERROR = 'setError'
export const SET_STATISTIQUE = 'setStatistique'
export const SET_STATISTIQUES = 'setStatistiques'
export const SET_USERS_THEMES = 'setUsersThemes'
export const RESET_ERROR = 'resetError'

const state = {
    error: null,
    statistiques: [],
    statistique: {},
    usersThemes: []
}

const getters = {
    listStatistiques(state) {
        return state.statistiques
    },
    getStatistique(state) {
        return state.statistique
    },
    getUsersThemes(state) {
        return state.usersThemes
    }
}

const actions = {
    // LIST
    [LIST_STATISTIQUES](context, data) {
        return new Promise(resolve => {
            ApiService.query('formation/statistiques/tauxseuil/' + data.data.customerId + '/' + data.data.themeId, {
                params: data.data.params
            }).then(result => {
                context.commit(SET_STATISTIQUES, result.data)
                resolve(result)
            })
        })
    },
    // GET
    [GET_STATISTIQUE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get('formation/statistiques/tauxseuil', credentials.id)
                .then(({ data }) => {
                    context.commit(SET_STATISTIQUE, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [GET_USERS_THEMES](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.query('usersthemes/' + data.customerId + '/' + data.themeId + '/' + data.expiry)
                .then(({ data }) => {
                    context.commit(SET_USERS_THEMES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_STATISTIQUE](state, data) {
        state.statistique = data
        state.error = null
    },
    [SET_STATISTIQUES](state, data) {
        state.statistiques = data
        state.error = null
    },
    [SET_USERS_THEMES](state, data) {
        state.usersThemes = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
