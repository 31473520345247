// intern types
import axios from 'axios'
import authHeader from '@/core/services/store/api-orion/auth-header'

// action types
export const LIST_INTERN_ACTIONS = 'listInternActions'
export const LIST_ACTIONS_DETAILS = 'listActionsDetails'
export const CERTIFICATES = 'listCertificates'
export const LIST_ACTIONS = 'listActions'
export const GET_ACTIONS_BY_CLIENT_AND_PRODUCT_CODE = 'getActionsByClientAndProductCode'
export const GET_ACTION_PLANNING = 'getActionPlanning'

// mutation types
export const SET_ERROR = 'setError'
export const SET_ACTIONS = 'setActions'
export const SET_ACTION = 'setAction'
export const SET_ACTION_PLANNING = 'setActionPlanning'
export const SET_CERTIFICATES = 'setCertificates'
export const SET_ACTION_DETAILS = 'setActionDetails'
export const RESET_ERROR = 'resetError'
export const RESET_ACTION_STATE = 'resetActionState'
export const RESET_ACTION_PLANNING = 'resetActionPlanning'

const getDefaultState = () => {
    return {
        error: null,
        actions: [],
        actionDetails: {},
        certificates: {},
        allActions: [],
        dates: [],
        action: {},
        actionsPlanning: []
    }
}

const API_URL = process.env.VUE_APP_API_ORION_URL

const state = getDefaultState()

const getters = {
    listActions(state) {
        return state.actions
    },
    listActionsDetails(state) {
        return state.actionDetails
    },
    listCertificates(state) {
        return state.certificates
    },
    getAction(state) {
        return state.action
    },
    getActionPlanning(state) {
        return state.actionsPlanning
    }
}

const actions = {
    // LISTE DES ACTIONS ORION SUIVI PAR LE STAGIAIRE
    [LIST_INTERN_ACTIONS](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'actions_clients/stagiaire/' + params.id, { headers: authHeader() })
                .then(({ data }) => {
                    context.commit(SET_ACTIONS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [LIST_ACTIONS_DETAILS](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'actions_clients/societe/' + params.params.societe + '/details/' + params.params.id, {
                    headers: authHeader()
                })
                .then(({ data }) => {
                    context.commit(SET_ACTION_DETAILS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [CERTIFICATES](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'actions_clients/certificat/societe/' + params.params.societe + '/action_client/' + params.params.id, {
                    headers: authHeader()
                })
                .then(({ data }) => {
                    context.commit(SET_CERTIFICATES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [LIST_ACTIONS](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'actions_clients', { params: params.params, headers: authHeader() })
                .then(({ data }) => {
                    context.commit(SET_ACTIONS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [GET_ACTION_PLANNING](context) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'actions_clients/planning', { headers: authHeader() })
                .then(({ data }) => {
                    context.commit(SET_ACTION_PLANNING, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [GET_ACTIONS_BY_CLIENT_AND_PRODUCT_CODE](context, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + 'actions_clients/stats/' + params.customer_id + '/' + params.code_produit_id, {
                    headers: authHeader()
                })
                .then(({ data }) => {
                    context.commit(SET_ACTIONS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_ACTIONS](state, data) {
        state.actions = data
        state.error = null
    },
    [SET_ACTION](state, data) {
        state.action = data
        state.error = null
    },
    [SET_ACTION_DETAILS](state, data) {
        state.actionDetails = data
        state.error = null
    },
    [SET_CERTIFICATES](state, data) {
        state.certificates = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    },
    [RESET_ACTION_STATE](state) {
        Object.assign(state, getDefaultState())
    },
    [SET_ACTION_PLANNING](state, data) {
        state.actionsPlanning = data
        state.error = null
    },
    [RESET_ACTION_PLANNING](state) {
        state.actionsPlanning = []
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
