import ApiService from '@/core/services/api.service'

// intern types
export const LIST_INTERNS = 'listInterns'
export const GET_INTERN = 'getIntern'
export const POST_INTERN = 'createIntern'
export const GET_ROLE = 'getRole'

// mutation types
export const SET_ERROR = 'setError'
export const SET_INTERNS = 'setInterns'
export const SET_INTERN = 'setIntern'
export const SET_ROLE = 'setRole'
export const RESET_ERROR = 'resetError'

const state = {
    error: null,
    interns: [],
    intern: {},
    countInterns: 0,
    countAllInterns: 0
}

const getters = {
    listInterns(state) {
        return state.interns
    },
    getIntern(state) {
        return state.intern
    },
    getRole(state) {
        return state.role
    }
}

const actions = {
    // LIST
    [LIST_INTERNS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('formation/interns', params)
                .then(({ data }) => {
                    context.commit(SET_INTERNS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    // GET
    [GET_INTERN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('formation/interns/' + credentials.id)
                .then(({ data }) => {
                    context.commit(SET_INTERN, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [POST_INTERN](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('users/create', params)
                .then(({ data }) => {
                    context.commit(SET_INTERN, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },

    [GET_ROLE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get('formation/interns/role', credentials)
                .then(({ data }) => {
                    context.commit(SET_ROLE, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_INTERNS](state, data) {
        state.interns = data
        state.error = null
    },
    [SET_ROLE](state, data) {
        state.role = data
        state.error = null
    },
    [SET_INTERN](state, data) {
        state.intern = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
