import ApiService from '@/core/services/api.service'

export const LIST_CUSTOMERS_WITH_FILLIALE = 'listCustomersWithFilliale'
export const GET_CUSTOMER = 'getCustomer'
export const SET_CUSTOMERS = 'setCustomers'
export const SET_CUSTOMER = 'setCustomer'
export const SET_ERROR = 'setError'
export const RESET_ERROR = 'resetError'

const state = {
    errors: null,
    client: {},
    info_client: {},
    classes: [],
    clientClasses: []
}

const getters = {}

const actions = {
    [LIST_CUSTOMERS_WITH_FILLIALE](context, param) {
        return new Promise((resolve, reject) => {
            ApiService.query('customers/' + param + '/filliale')
                .then(({ data }) => {
                    context.commit(SET_CUSTOMERS, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [GET_CUSTOMER](context, param) {
        return new Promise((resolve, reject) => {
            ApiService.query('customers/' + param)
                .then(({ data }) => {
                    context.commit(SET_CUSTOMER, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [RESET_ERROR](state) {
        state.error = null
    },
    [SET_CUSTOMERS](state, data) {
        state.customers = data
    },
    [SET_CUSTOMER](state, data) {
        state.customer = data
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
