import ApiService from '@/core/services/api.service'

export const LIST_THEMES = 'listThemes'
export const GET_THEME = 'getTheme'
export const LIST_THEMES_BY_CUSTOMER_ID = 'listThemesByCustomerId'

// mutation types
export const SET_ERROR = 'setError'
export const SET_THEMES = 'setThemes'
export const SET_THEME = 'setTheme'
export const RESET_ERROR = 'resetError'

const state = {
    error: null,
    themes: [],
    theme: {}
}

const getters = {
    listThemes(state) {
        return state.themes
    },
    listThemesByCustomerId(state) {
        return state.themes
    },
    getTheme(state) {
        return state.theme
    }
}

const actions = {
    // LIST
    [LIST_THEMES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('themes', params)
                .then(({ data }) => {
                    context.commit(SET_THEMES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    [LIST_THEMES_BY_CUSTOMER_ID](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('themes/customer/' + params)
                .then(({ data }) => {
                    context.commit(SET_THEMES, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    },
    // GET
    [GET_THEME](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('themes/' + credentials)
                .then(({ data }) => {
                    context.commit(SET_THEME, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_THEMES](state, data) {
        state.themes = data
        state.error = null
    },
    [SET_THEME](state, data) {
        state.theme = data
        state.error = null
    },
    [RESET_ERROR](state) {
        state.error = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
