import ApiService from '@/core/services/api.service'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const REFRESH_TOKEN = 'refreshToken'

// mutation types
export const PURGE_AUTH = 'purgeAuth'
export const SET_AUTH = 'setUser'
export const SET_TOKEN_LIFETIME = 'setTokenLifetime'
export const SET_ERROR = 'setError'

const state = {
    errors: null,
    user: {},
    isAuthenticated: false,
    tokenLifetime: null
}

const getters = {
    currentUser(state) {
        return state.user
    },
    isAuthenticated(state) {
        return state.isAuthenticated
    },
    tokenLifetime(state) {
        return state.tokenLifetime
    }
}

const actions = {
    [VERIFY_AUTH](context) {
        return new Promise((resolve, reject) => {
            ApiService.query('user')
                .then(({ data }) => {
                    context.commit(SET_AUTH, data.data)
                    resolve(data)
                })
                .catch(response => {
                    context.commit(SET_ERROR, response)
                    context.commit(PURGE_AUTH)
                    reject(response)
                })
        })
    },
    [REFRESH_TOKEN](context) {
        return new Promise((resolve, reject) => {
            ApiService.query('refresh-token')
                .then(({ data }) => {
                    context.commit(SET_TOKEN_LIFETIME, data.lifetime)
                    resolve(data)
                })
                .catch(response => {
                    context.commit(SET_ERROR, response)
                    context.commit(PURGE_AUTH)
                    reject(response)
                })
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true
        state.user = user
        state.user.avatar = state.user.avatar
            ? state.user.avatar
            : `https://api.dicebear.com/7.x/initials/svg?seed=${state.user.firstname} ${state.user.lastname}`
        state.errors = {}
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false
        state.user = {}
        state.errors = {}
    },
    [SET_TOKEN_LIFETIME](state, tokenLifetime) {
        state.tokenLifetime = tokenLifetime
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
