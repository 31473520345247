import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'
import { VERIFY_AUTH } from '@/core/services/store/api/auth'
import { AUTH_API_ORION } from '@/core/services/store/api-orion/auth'

Vue.config.productionTip = false

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'

window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'

window.ClipboardJS = ClipboardJS

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/metronic'
import '@/core/plugins/treeselect'
import '@mdi/font/css/materialdesignicons.css'
import '@/core/plugins/v-calendar'
import '@/core/plugins/vue-moment'
import '@/core/plugins/vue-friendly-iframe'
import vuetify from './core/plugins/vuetify'
// Vue components & filters...
import '@/core/components/currency-filter'
import '@/core/components/vue-typeahead-bootstrap'
import '@/core/components/vue-pdf'
import { LIST_RIGHTS } from '@/core/services/store/api/rights'
import { GET_ROLE } from '@/core/services/store/api/intern'
import vueExcelXlsx from './core/plugins/vue-excel-xlsx'
import VueDatePicker from '@/core/plugins/vue-datepicker'

// API service init
ApiService.init()

router.beforeEach(async (to, from, next) => {
    // Ensure we checked auth before each page load.
    await store.dispatch(VERIFY_AUTH).catch(error => {
        var url = `${process.env.VUE_APP_PLATEFORME_URL}/login?service=${process.env.VUE_APP_NAME}`
        if (error.response && error.response.status === 403) {
            url = `${process.env.VUE_APP_PLATEFORME_URL}/logout?service=${process.env.VUE_APP_NAME}`
        } else if (error.response && error.response.status === 401) {
            url = `${process.env.VUE_APP_PLATEFORME_URL}/login?service=${process.env.VUE_APP_NAME}&disconnected=true`
        }
        window.location.replace(url)
    })
    if (!store.getters.token) {
        await store.dispatch(AUTH_API_ORION)
        await store.dispatch(GET_ROLE, store.getters.currentUser.id)
        await store.dispatch(LIST_RIGHTS, store.getters.currentUser.id)
    }

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({
            ...context,
            next: nextMiddleware
        })
    } else {
        return next()
    }
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index] // If no subsequent Middleware exists,
    // the default `next()` callback is returned.

    if (!subsequentMiddleware) return context.next

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters) // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.

        const nextMiddleware = nextFactory(context, middleware, index + 1)
        subsequentMiddleware({
            ...context,
            next: nextMiddleware
        })
    }
}

Vue.mixin({
    methods: {
        isMobile() {
            return window.screen.availWidth <= 992
        }
    }
})

new Vue({
    router,
    store,
    i18n,
    vuetify,
    vueExcelXlsx,
    VueDatePicker,
    render: h => h(App)
}).$mount('#app')
