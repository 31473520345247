import store from "@/core/services/store";

export default function intern({ next }) {
    if (store.getters.getRole.name === 'Gestionnaire' && store.getters.listRights.length !== 0) {
        var rights = store.getters.listRights
        rights.forEach(right => {
            if (right.name !== 'Factures') {
                return next({ name: 'home' })
            }
        })
    } else {
        return next({ name: 'home' })
    }

    return next()
}
